import React, { useState, useEffect } from 'react';

type Data = {
  Area: string;
  Address_number: string;
  Address_1: string;
  Address_2: string;
  Date: string;
  user_id: string | null;
};

const App: React.FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://kolenbro.synology.me:7000/KB/');
        const result: Data[] = await response.json();
        setData(result);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching the data:', error);
        setIsLoading(false);
      }
    };

    const interval = setInterval(fetchData, 500); // 0.5초 간격으로 fetchData 함수를 실행

    return () => clearInterval(interval); // 컴포넌트가 언마운트될 때 인터벌을 정리
  }, []);

  const getAreaStyle = (area: string) => {
    switch (area) {
      case 'A':
        return { backgroundColor: 'red', color: 'white' };
      case 'B':
        return { backgroundColor: 'purple', color: 'white' };
      case 'C':
        return { backgroundColor: 'green', color: 'white' };
      case 'D':
        return { backgroundColor: 'blue', color: 'white' };
      default:
        return {};
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (data.length === 0) {
    return <div>No data available</div>;
  }

  const address = data[0].Address_1;
  const firstPart = address.slice(0, 25);
  const secondPart = address.slice(25);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '10vh' }}>
      {/* Upper Section */}
      <div style={{ display: 'flex', flex: '0.5', borderBottom: '1px solid black' }}>
        <div style={{ backgroundColor: '#eee', flex: '0.5', padding: '20px', borderRight: '1px solid black' }}>
          <h2>Address</h2>
          <p style={{ fontSize: '40px' }}>
            {firstPart}
            {secondPart && <br />}
            {secondPart}
          </p>
        </div>
        <div style={{ ...getAreaStyle(data[0].Area), flex: '0.5', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
         <h2>Area</h2>
          <p style={{ fontSize: '100px' }}>{data[0].Area}</p>
        </div>
      </div>

      {/* Lower Section */}
      <div style={{ backgroundColor: '#fff', padding: '10px', flex: '0.5' }}>
        <h2 style={{ fontSize: '20px' }}>history 10</h2>
        <ul>
          {data.map((item, index) => (
            <li key={index}>
              <p style={{ fontSize: '20px' }}>{item.Address_number} - {item.Address_1} - {item.user_id} - {item.Date}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default App;
